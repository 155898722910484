html{
  background: #eee5;
}
body {
  margin: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
}

#root {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}

@keyframes rgb-grandient {
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button { 
-webkit-appearance: none;
margin:0;
}

/* Opéra*/
input::-o-inner-spin-button,
input::-o-outer-spin-button { 
-o-appearance: none;
margin:0
}